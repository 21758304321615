<template>
  <div class="centre">
    <div class="centre_ul">
      <div class="centre_li" v-for="(item, index) in couponData">
        <div class="content">
          <div class="price">
            <div class="data">
              <span>￥</span> <b>{{ item.vouchertemplate_price }}</b>
            </div>
            <p>满{{ item.vouchertemplate_limit }}可用</p>
          </div>
          <div class="solid"></div>
          <div class="info">
            <div class="title">
              【满减券】
            </div>
            <div class="time">
              有效期到{{ item.vouchertemplate_enddate_text }}
            </div>
          </div>
          <div
            class="btn"
            @click="receiveVoucher(item.vouchertemplate_id, index)"
            v-if="item.vouchertemplate_used == 0"
          >
            领取
          </div>

          <div
            class="btn btn-act"
            @click="goRouter()"
            v-if="item.vouchertemplate_used == 1"
          >
            使用
          </div>
        </div>
        <div class="more">
          <oShowText
            :show="false"
            :vouchertemplate_title="item.vouchertemplate_title"
            :vouchertemplate_desc="item.vouchertemplate_desc"
          ></oShowText>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Collapse, CollapseItem, List, Toast } from "vant";
import noData from "@/components/noData/";
import { getReceiveVoucher } from "./js/";
import oShowText from "./components/showText";
import { post } from "@get/http";
export default {
  components: {
    vanCollapse: Collapse,
    vanCollapseItem: CollapseItem,
    oShowText,
  },
  data: () => ({
    loading: false,
    finished: false,
    couponData: [],
  }),
  methods: {
    async receiveVoucher(tid, index) {
      Toast.loading({
        duration: 0,
        message: "领取中",
        forbidClick: true,
      });
      await getReceiveVoucher(tid);
      Toast("领取成功");
      this.couponData[index].vouchertemplate_used = 1;
    },
    goRouter() {
      this.$router.push({
        name: "HomeIndex",
      });
    },
    async onLoad() {
      this.loading = true;
      let { voucher_list } = await post("/Voucher/voucher_tpl_list", {
        data: {
          store_id: this.$route.query?.store_id || 2,
          gettype: 2,
          is_show: 0, //0是显示  1是隐藏
        },
      });
      if (voucher_list) {
        if (voucher_list.length > 0) {
          for (var i = 0; i < voucher_list.length; i++) {
            if (voucher_list[i].goods_info.length > 0) {
              voucher_list[i].goods_info = JSON.parse(
                voucher_list[i].goods_info
              );
            }
          }
        }
      }
      if (!voucher_list) {
        voucher_list = [];
      }
      this.couponData.push(...voucher_list);
      this.loading = true;
      this.finished = true;
    },
  },
  created() {
    //do something after creating vue instance
    this.onLoad();
  },
};
</script>

<style lang="scss" scoped>
@import "~@css/mixin.scss";

.centre_ul {
  width: rem(750);
  margin: 0 auto;
  padding: rem(24) rem(24);
  box-sizing: border-box;
  .centre_li {
    background: #ffffff;
    border-radius: rem(16);
    margin-bottom: rem(24);
  }

  .content {
    display: flex;
    justify-content: center;
    align-items: center;
    border-bottom: 1px dashed #eeeeee;
    .solid {
      background: #eeeeee;
      width: rem(2);
      height: rem(114);
      margin-right: rem(16);
    }
    .price {
      width: rem(180);
      height: rem(180);
      color: #ff4848;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      .data {
        span {
          margin-right: rem(8);
          font-weight: 600;
          font-size: rem(30);
          line-height: rem(42);
        }
        b {
          font-weight: 600;
          font-size: rem(64);
          line-height: rem(90);
          /* identical to box height */

          /* 商城红 */
        }
      }
      p {
        font-weight: 400;
        font-size: rem(24);
        line-height: rem(34);
        color: #666666;
      }
    }
    .info {
      flex: 1;
      display: flex;
      justify-content: center;
      flex-direction: column;
      .title {
        margin-left: rem(16);
        font-weight: 600;
        font-size: rem(30);
        line-height: rem(42);
        color: #222222;
        margin-bottom: rem(16);
      }
      .time {
        font-weight: 400;
        font-size: rem(24);
        line-height: rem(34);
        color: #666666;
      }
    }
    .btn {
      background: #ff4848;
      border-radius: rem(8);
      width: rem(120);
      height: rem(64);
      line-height: rem(64);
      text-align: center;
      font-weight: 400;
      font-size: rem(24);
      color: #ffffff;
      margin: 0 rem(16);
    }
    .btn-act {
      color: #ff4848;
      border: rem(2) solid #ff4848;
      background: #fff;
    }
  }

  .more {
    padding: 0 rem(32);
    box-sizing: border-box;
  }
}
</style>
