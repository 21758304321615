<template>
  <div>
    <div class="showMore" @click="showState()">
      <span>查看详情</span>
      <svg-icon iconClass="coupon_jt" class="tr"></svg-icon>
    </div>

    <div class="textarea" v-if="boo == true">
      <h1>{{ vouchertemplate_title }}</h1>
      <p>{{ vouchertemplate_desc }}</p>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    show: {
      default: false,
    },
    vouchertemplate_title: {
      default: "",
    },
    vouchertemplate_desc: {
      default: "",
    },
  },
  methods: {
    showState() {
      console.log("?????");
      this.boo = !this.boo;
    },
  },
  data() {
    return {
      boo: false,
    };
  },
  created() {
    this.boo = this.show;
  },
};
</script>

<style lang="scss" scoped>
@import "~@css/mixin.scss";
.showMore {
  display: flex;
  padding: rem(24) 0;
  span {
    color: #666666;
    font-weight: 400;
    font-size: rem(24);
    line-height: rem(34);
  }
  svg {
    width: rem(32);
    height: rem(32);
  }
}
.tr {
  transform: rotate(180);
  transition: 0.5s;
}
.textarea {
  h1 {
    color: #222222;
    margin-bottom: rem(24);
  }
  font-weight: 400;
  font-size: rem(24);
  line-height: rem(34);
  color: #666666;
  padding-bottom: rem(40);
}
</style>
